.card-detalle {
    /* text-align: center;
    width: 50px;
    justify-content: center; */
    color: rgb(65, 65, 119);
}

button {
    margin: 13px 12px 12px 10px;
}

O button {
    margin: 13px;
}

@media (max-width:580px) {
    .detalle p {
        display: block;
        /* width: 100%; */
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
        align-items: center;
        padding: 0 15px;
    }
}